/* -------------- footer RWD --------------*/
// @media (max-width: $w_pc_b){ }

@media (max-width: $w_pc){ 
    .footer{
        padding: 40px 0 50px;
        .logo{
            width: 300px;
        }
        .ft-menu{
            gap: 5px 20px;
        }
    }
}

@media (max-width: $w_pc_m){ 
    .footer {
        .frame{
            align-items: center;
            flex-direction: column;
        }
        .ft-right{
            margin-top: 20px;
        }
        .web-rights{
            justify-content: center;
        }
    }
}

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .footer {
        padding: 30px 0;
        .logo{
            width: 250px;
        }
        .ft-menu {
            justify-content: center;
            a{
                font-size: .9375rem /* 15/16 */;
            }
        }
        .web-rights{
            align-items: center;
            flex-direction: column;
        }
    }
    .top-btn{
        @include size(35px);
        span{
            font-size: .75rem /* 12/16 */;
        }
    }
}

// @media (max-width: $w_tb_m){ }

// @media (max-width: $w_mo){ }