:root{
    --c-font: #222;
    --c-fontLight: #999999;
    --c-primary: #1cb89b;
    --c-light: #d9eeea;
    --c-success: #9871ad;
    --c-white: #fff;
    --c-grey: #dddddd;
    --c-border: #e5e5e5;
}

$c_font: #222;
$c_white: #fff;
$c_main: #2bbea4;
$c_dark: #334a8d;
$c_active: #9871ad;
$c_grey: #dddddd;

$w_pc_b: calc(1440px + 40px);
$w_pc: 1280px;
$w_pc_m: 1024px;
$w_tb_b: 960px;
$w_tb: 768px;
$w_tb_m: 640px;
$w_mo: 480px;
$w_i6p: 414px;
$w_i6: 375px;
$w_mo_m: 320px;

$fz: 10px;

$fa-font-path: "../webfonts";