/* -------------- service --------------*/
.page-service{
    p{
        margin: 0;
    }
    .service-en{
        color: var(--c-font);
        font-size: 1.5rem /* 24/16 */;
        font-weight: lighter;
        line-height: 1.2;
        margin-bottom: 20px;
    }
    .service-table{
        ol, ul{
            padding-left: 20px;
            margin-bottom: 0;
        }
        li + li{
            margin-top: 5px;
        }
        th, td{
            &:nth-child(1){
                font-weight: bold;
                text-align: center;
                width: 160px;
            }
        }
        .service-item-title{
            @include flex($ai:baseline);
            color: var(--c-font);
            font-weight: bold;
            font-size: 1.0625rem /* 17/16 */;
            line-height: 1.2;
            gap: 5px 15px;
            margin-bottom: 10px;
            span{
                display: inline-block;
            }
        }
        p{
            color: var(--c-font);
            font-size: 1rem /* 16/16 */;
            line-height: 1.5;
            + p{
                margin-top: 3px;
            }
        }
        svg{
            color: var(--c-fontLight);
            font-size: .875rem /* 14/16 */;
            padding-right: 5px;
        }
    }
}