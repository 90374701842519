/* -------------- header --------------*/
header{
    position: sticky;
    top: 0;
    z-index: 3;
    &:before{
        @include abs_pos($b:0, $l:0);
        @include size(100%, 5px);
        content: "";
        background: url(../images/h-shadow.png);
        background-size: cover;
        background-position: top center;
        transform: translateY(100%);
    }
}

.top-header{
    @extend %clearStyle;
    background: var(--c-primary);
    .frame{
        @include flex($jfc:flex-end);
    }
    button, a{
        @include size(50px);
        @include flex($ai:center, $jfc:center);
        svg{
            font-size: 1.25rem /* 20/16 */;
            line-height: 1;
        }
    }
    .keyword-form{
        @include placeholder(#eee);
        align-items: center;
        display: grid;
        grid-template-columns: auto 50px;
        input{
            background: none;
            color: var(--c-white);
            font-size: .9375rem /* 15/16 */;
            line-height: 1;
            padding: 0;
            padding-right: 10px;
            width: 220px;
        }
        button{
            background: none;
            border: none;
            padding: 0;
            margin: 0;
            &:hover svg{
                color: var(--c-font);
            }
            svg{
                color: var(--c-white);
            }
        }
    }
    .sitemap-link{
        background: var(--c-success);
        &:hover{
            background: var(--c-white);
            svg{
                color: var(--c-success);
            }
        }
        svg{
            color: var(--c-white);
        }
    }
    .member-link{
        background: var(--c-white);
        &:hover{
            background: var(--c-fontLight);
            svg{
                color: var(--c-white);
            }
        }
        svg{
            color: var(--c-primary);
        }
    }
}

.header{
    @extend %clearStyle;
    background: var(--c-white);
    .frame{
        @include flex($ai:center);
        justify-content: space-between;
    }
    .logo{
        display: block;
        width: 330px;
        img{
            display: block;
            width: 100%;
        }
    }
    .active-row{
        @include flex($ai:center);
        gap: 0 35px;
    }
    .main-menu{
        @include flex;
        gap: 0 25px;
        > li{
            padding: 45px 0;
            position: relative;
            &:hover .main-link{
                color: var(--c-primary);
            }
        }
        .main-link{
            color: var(--c-font);
            font-size: 1.1875rem /* 19/16 */;
            font-weight: bold;
            line-height: 1;
            padding-right: 15px;
            position: relative;
            &:before{
                @include abs_pos($t:50%, $r:0);
                content: '\f107';
                color: var(--c-fontLight);
                font-size: .875rem /* 14/16 */;
                font-family: 'Font Awesome 6 Free';
                font-weight: bold;
                line-height: 1;
                transform: translateY(-50%);
            }
        }
    }
    .sub-menu{
        @include abs_pos($b:0, $l:50%);
        background: var(--c-primary);
        transform: translate(-50%, 100%);
        padding: 4px 0;
        width: 150px;
        display: none;
        a{
            color: var(--c-white);
            font-size: 1rem /* 16/16 */;
            line-height: 1.2;
            display: block;
            text-align: center;
            padding: 15px 10px;
            &:hover{
                background: var(--c-white);
                color: var(--c-primary);
            }
        }
    }
    .nhl-link{
        @include size(50px);
        display: block;
        img{
            display: block;
            width: 100%;
        }
    }
}

.mobile-toggle {
    @include size(28px, 30px);
    background: none;
    cursor: pointer;
    display: none;
    transform: translateY(13px);
    padding: 0;
    z-index: 4;

    &.open {

        span,
        span:before,
        span:after {
            background: $c_active;
        }

        span {
            background: none;

            &:before {
                transform: rotate(45deg) translate(6px, 5px);
            }

            &:after {
                transform: rotate(-45deg) translate(7px, -8px);
            }
        }
    }

    span {
        display: block;
        position: relative;

        &:before,
        &:after {
            @include abs_pos($t: -9px, $l: 0);
            content: "";
        }

        &:after {
            top: 9px;
        }
    }

    span,
    span:before,
    span:after {
        @include size(100%, 4px);
        backface-visibility: hidden;
        border-radius: 2px;
        background: $c_font;
        transition: all .4s ease;
    }
}