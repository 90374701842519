/* -------------- contact --------------*/
.page-contact{
    @extend %clearStyle;
    .info-grid{
        @include flex($jfc:space-between);
        max-width: 1220px;
        margin: auto;
        gap: 20px 0;
    }
    .info{
        align-self: flex-start;
        display: flex;
        gap: 15px;
        .icon{
            display: block;
            flex-shrink: 0;
            margin-top: 5px;
            height: 30px;
        }
        .content{
            .title{
                font-size: 1.0625rem /* 17/16 */;
                font-weight: bold;
                margin-bottom: 5px;
            }
            p{
                color: var(--c-font);
                font-size: 1rem /* 16/16 */;
                line-height: 1.2;
            }
            a{
                color: var(--c-primary);
                &:not(.fax):hover{
                    text-decoration: underline;
                    text-underline-offset: 5px;
                }
                &.fax{
                    color: var(--c-font);
                    cursor: auto;
                }
            }
        }
    }
    .map-row{
        background: #f9f9f9;
        display: grid;
        grid-template-columns: 35% auto;
        margin-top: 50px;
        .map{
            min-height: 220px;
            iframe{
                @include size(100%);
            }
        }
        .traffic-grid{
            padding: 50px;
        }
    }
    .traffic-table{
        border-collapse: collapse;
        width: 100%;
        th{
            font-size: 1rem /* 16/16 */;
            font-weight: normal;
            line-height: 1;
            padding: 14px 10px;
            b{
                display: inline-block;
                padding-left: 10px;
            }
        }
        td{
            padding: 18px 10px;
            &:nth-child(1){
                text-align: center;
            }
        }
        tbody tr{
            border-bottom: 1px solid #eaeaea;
        }
        .bus-td{
            background: var(--c-primary);
            color: var(--c-white);
            width: 85px;
        }
        .content-td{
            background: var(--c-white);
            border: 1px solid #eaeaea;
            color: var(--c-font);
        }
        .no{
            color: var(--c-primary);
            font-weight: bold;
            &.high-no{
                color: var(--c-success);
            }
        }
        .bus-info{
            align-items: flex-start;
            display: flex;
            color: var(--c-font);
            font-size: 1rem /* 16/16 */;
            line-height: 1.3;
            gap: 7px;
            p:first-child{
                flex-shrink: 0;
            }
            .icon{
                flex-shrink: 0;
                text-align: center;
                width: 20px;
                svg{
                    color: #995eba;
                    font-size: .75rem /* 12/16 */;
                }
            }
        }
    }
}