/* -------------- sitemap --------------*/
.page-sitemap{
    @extend %clearStyle;
    .sitemap-head{
        @include maw(320px);
        background: var(--c-font);
        color: var(--c-white);
        font-size: 1.0625rem /* 17/16 */;
        font-weight: bold;
        padding: 10px;
        text-align: center;
        margin: auto;
    }
    .sitemap-grid{
        margin-top: 50px;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 40px 70px;
    }
    .sitemap{
        .title{
            color: var(--c-font);
            font-size: 1.125rem /* 18/16 */;
            font-weight: bold;
            line-height: 1.2;
            text-decoration: underline;
            text-underline-offset: 10px;
        }
        .links{
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 30px;
            a{
                background: #efefef;
                display: block;
                color: var(--c-font);
                font-size: 1rem /* 16/16 */;
                line-height: 1.4;
                padding: 10px 20px;
                &:hover{
                    background: var(--c-light);
                    color: var(--c-primary);
                }
            }
        }
    }
}