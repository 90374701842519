/* -------------- member --------------*/
.page-member{
    .login-form{
        @include maw(420px);
        margin: auto;
        .field{
            align-items: center;
            display: grid;
            grid-template-columns: 60px auto;
            + .field{
                margin-top: 20px;
            }
        }
        .wide-field{
            grid-template-columns: 1fr;
        }
        .captcha-field{
            justify-items: center;
        }
        .field-label{
            p{
                color: var(--c-font);
                font-size: 1.0625rem /* 17/16 */;
                line-height: 1;
                margin: 0;
            }
        }
        .field-content{
            input[type="text"], input[type="password"]{
                border: 1px solid var(--c-font);
                font-size: 1rem /* 16/16 */;
                line-height: 1;
                padding: 12px;
                width: 100%;
            }
        }
        .submit{
            background: #efefef;
            color: var(--c-font);
            font-size: 1rem /* 16/16 */;
            line-height: 1;
            padding: 18px 15px;
            width: 100%;
            &:hover{
                background: var(--c-font);
                color: var(--c-white);
            }
        }
    }
    .member-top-row{
        display: flex;
        justify-content: space-between;
        .member-info{
            display: flex;
            padding-top: 15px;
            .member-name{
                color: var(--c-font);
                font-size: 1rem /* 16/16 */;
                line-height: 1;
                margin: 0;
                position: relative;
                &:after{
                    content: '/';
                    color: var(--c-fontLight);
                    display: inline-block;
                    padding: 0 8px;
                }
                span{
                    color: var(--c-primary);
                    display: inline-block;
                    padding-right: 5px;
                }
            }
            .log-out{
                color: var(--c-primary);
                font-size: 1rem /* 16/16 */;
                line-height: 1;
                &:hover{
                    color: var(--c-success);
                }
            }
        }
    }
    .member-aside{
        @include flex;
        margin: 20px 0 40px;
        a{
            border: 1px solid #eaeaea;
            background: #eaeaea;
            color: var(--c-font);
            font-size: 1.1875rem /* 19/16 */;
            display: block;
            line-height: 1;
            padding: 15px 30px;
            &:hover{
                background: rgba(#9871ad, .1);
                border-color: rgba(#9871ad, .1);
                color: var(--c-success);
            }
            &.active{
                background: none;
                color: var(--c-success);
            }
        }
    }
    .member-top-des{
        margin-bottom: 30px;
        p{
            color: var(--c-font);
            font-size: 1rem /* 16/16 */;
            line-height: 1.4;
            margin: 0;
            + p{
                margin-top: 8px;
            }
        }
        span{
            display: inline-block;
        }
        .member-name{
            padding-right: 5px;
        }
        .account-no{
            color: var(--c-primary);
            padding-left: 5px;
        }
    }
    .member-form{
        background: #f5f5f5;
        display: grid;
        grid-template-columns: 25% 35% auto 60px;
        padding: 15px 0;
        .field{
            align-items: center;
            display: flex;
            padding: 0 20px;
            &:not(:last-child){
                position: relative;
                &:after{
                    @include abs_pos($t:15%, $r:-3px);
                    @include size(1px, 70%);
                    border-left: 1px solid #d4d4d4;
                    border-right: 2px solid var(--c-white);
                    content: '';
                }
            }
        }
        .select-style{
            background: none;
            flex-shrink: 0;
            select{
                color: var(--c-font);
                font-size: 1rem /* 16/16 */;
                line-height: 1;
                padding: 0;
                padding-right: 25px;
            }
        }
        .date-range{
            align-items: center;
            display: flex;
            gap: 5px;
            width: 100%;
        }
        input[type="text"], input[type="date"]{
            color: var(--c-font);
            font-size: 1rem /* 16/16 */;
            line-height: 1;
            padding: 10px;
            width: 100%;
        }
        .submit{
            background: none;
            color: var(--c-font);
            font-size: 1.25rem /* 20/16 */;
            padding: 0;
            &:hover{
                color: var(--c-primary);
            }
        }
    }
    .report-table-limit{
        margin-top: 30px;
        overflow-x: auto;
    }
    .report-table{
        min-width: 1200px;
        th, td{
            padding: 13px 10px;
            text-align: center;
        }
        p{
            color: var(--c-font);
            font-size: 1rem /* 16/16 */;
            line-height: 1.2;
            margin: 0;
            word-break: keep-all;
        }
        tbody tr{
            &:nth-child(2n){
                background: #f8f8f8;
            }
        }
        a{
            color: var(--c-primary);
            &:hover{
                color: var(--c-success);
            }
        }
        .des-td{
            text-align: left;
        }
    }
    .report-filter-row{
        display: flex;
        gap: 15px;
        margin-top: 30px;
        margin-bottom: 50px;
        .title{
            color: var(--c-font);
            font-size: 1rem /* 16/16 */;
            font-weight: bold;
            line-height: 1;
            flex-shrink: 0;
            margin: 0;
            margin-top: 10px;
        }
        .filter-list{
            @include flex($ai:flex-start);
            gap: 10px;
            a{
                border-radius: 30px;
                color: var(--c-font);
                font-size: .875rem /* 14/16 */;
                line-height: 1;
                padding: 10px 15px;
            }
            .filter{
                background: #e3edeb;
                padding-right: 35px;
                position: relative;
                &:after{
                    @include abs_pos($t:50%, $r:15px);
                    content: '\f00d';
                    color: var(--c-fontLight);
                    font-size: .75rem /* 12/16 */;
                    font-weight: bold;
                    font-family: 'Font Awesome 6 Free';
                    transform: translateY(-50%);
                }
                &:hover{
                    background: var(--c-primary);
                    color: var(--c-white);
                    &:after{
                        color: var(--c-white);
                    }
                }
            }
            .clear-filter{
                border: 1px solid #e8e8e8;
                &:hover{
                    background: #e8e8e8;
                }
            }
        }
    }
    .report-main{
        margin-bottom: 40px;
        overflow-x: auto;
        p{
            margin: 0;
        }
        table{
            border-collapse: collapse;
            border: 2px solid var(--c-font);
            width: 100%;
            p{
                display: inline-block;
            }
            td{
                vertical-align: middle;
                border: 1px solid #e8e8e8;
                padding: 15px;
            }
            .field-title{
                font-weight: bold;
                padding-right: 15px;
                min-width: 150px;
            }
            .main-content p{
                display: block;
            }
        }
        .report-title{
            color: var(--c-font);
            font-weight: bold;
            font-size: 1.25rem /* 20/16 */;
            line-height: 1.2;
            text-align: center;
            margin-bottom: 20px;
        }
        .remark-row{
            @include flex($jfc:space-between, $ai:flex-start);
            padding: 25px 15px;
            .remark{
                p{
                    color: var(--c-font);
                    font-size: 1rem /* 16/16 */;
                    font-weight: bold;
                    line-height: 1.2;
                    + p{
                        margin-top: 8px;
                    }
                }
            }
            .rights{
                color: var(--c-font);
                font-size: .8125rem /* 13/16 */;
                font-weight: lighter;
            }
        }
    }
    .download-row{
        display: flex;
        justify-content: center;
        gap: 20px;
        a{
            background: #efefef;
            display: block;
            color: var(--c-primary);
            font-size: 1rem /* 16/16 */;
            line-height: 1;
            padding: 12px 10px;
            text-align: center;
            width: 120px;
            &:hover{
                background: var(--c-primary);
                color: var(--c-white);
            }
        }
    }
    .translation-table-main{
        margin-top: 60px;
    }
    .translation-head{
        color: var(--c-font);
        text-align: center;
        margin-bottom: 20px;
        p{
            margin: 0;
        }
        .title{
            font-size: 1.25rem /* 20/16 */;
            font-weight: bold;
        }
        .des{
            font-size: 1.125rem /* 18/16 */;
            line-height: 1.2;
            margin-top: 8px;
            span{
                display: inline-block;
            }
        }
    }
    .translation-table{
        border-collapse: collapse;
        width: 100%;
        p{
            color: var(--c-font);
            font-size: 1rem /* 16/16 */;
            line-height: 1.4;
            margin: 0;
        }
        th{
            background: #666666;
            padding: 10px;
            text-align: center;
            p{
                color: var(--c-white);
            }
        }
        td{
            border: 1px solid #e8e8e8;
            padding: 15px;
        }
        .sep-tr{
            background: #fbf8fd;
        }
        .item-name-td{
            font-weight: bold;
        }
        .no-td{
            text-align: center;
        }
    }
    .translation-remark{
        @include flex($jfc:space-between, $ai:flex-start);
        padding: 30px 20px;
        p{
            margin: 0;
        }
        .left-sec{
            color: var(--c-font);
            font-size: .9375rem /* 15/16 */;
            line-height: 1.4;
            p + p{
                margin-top: 5px;
            }
            .title{
                font-weight: bold;
            }
        }
        .right-sec{
            color: var(--c-font);
            p{
                font-size: .875rem /* 14/16 */;
                line-height: 1;
            }
        }
    }
}