/* -------------- download RWD --------------*/
// @media (max-width: $w_pc_b){ }

// @media (max-width: $w_pc){ }

// @media (max-width: $w_pc_m){ }

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .page-download{
        .download-item{
            .file-name{
                font-size: 1rem /* 16/16 */;
            }
        }
    }
}

@media (max-width: $w_tb_m){ 
    .page-download{
        .download-item{
            grid-template-columns: 1fr;
            .download-btn{
                padding: 8px 5px;
                width: 120px;
            }
        }
    }
}

// @media (max-width: $w_mo){ }