/* -------------- about --------------*/
.page-about{
    .about-bottom-dec{
        display: block;
        width: 100%;
    }
    .page-main-body{
        p{
            color: var(--c-font);
            font-size: 1rem /* 16/16 */;
            line-height: 1.4;
            margin: 0;
            + p{
                margin-top: 10px;
            }
        }
        .slogon{
            color: var(--c-primary);
            display: inline-block;
            font-family: "Noto Serif TC", serif;
            font-size: 1.75rem /* 28/16 */;
            padding-right: 5px;
        }
    }
    .eq-table{
        .eq-td {
            width: 33%;
            p{
                color: #179881;
                font-weight: bold;
            }
        }
    }
    .team-table{
        .name-td, .subject-td{
            padding-left: 15px;
            padding-right: 15px;
        }
        .name-td{
            text-align: center;
            width: 130px;
        }
        .subject-td{
            text-align: center;
            width: 100px;
        }
        span{
            display: inline-block;
        }
    }
}