/* -------------- about RWD --------------*/
// @media (max-width: $w_pc_b){ }

// @media (max-width: $w_pc){ }

// @media (max-width: $w_pc_m){ }

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .page-about {
        .eq-table{
            thead{
                display: none;
            }
            tr{
                display: block;
                +tr{
                    padding-top: 30px;
                }
            }
            td{
                border: none;
                display: block;
                padding: 10px 0 0;
                width: 100%;
            }
            .eq-td {
                display: inline-block;
                padding: 10px 15px;
                width: inherit;
            }
        }
        .team-table{
            thead{
                display: none;
            }
            tr{
                display: block;
                +tr{
                    padding-top: 30px;
                }
            }
            td{
                border: none;
                display: block;
                padding: 10px 0 0;
                width: 100%;
            }
            .name-td, .subject-td {
                display: inline-block;
                padding: 10px 15px;
                width: inherit;
            }
        }
    }
}

// @media (max-width: $w_tb_m){ }

// @media (max-width: $w_mo){ }