%clearfix{
	&:before, &:after{
		content: '';
		display: block;
	}
	&:after{
		clear: both;
	}
	zoom: 1;
}

%inside_img{
	display: block;
	max-width: 100%;
	max-height: 100%;
	margin: auto;
	position: absolute;
	top: 0; right: 0; left: 0; bottom: 0;
}

%center{
	position: absolute;
	top: 50%; left: 50%;
	transform: translateX(-50%) translateY(-50%);
}

%overdot{
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	white-space: nowrap;
	padding-bottom: 3px;
}

%clearStyle{
	p{
		margin: 0;
	}
	ul, ol{
		padding-left: 0;
		margin: 0;
	}
	ul{
		list-style: none;
	}
	ol{
		padding-left: 20px;
	}
}