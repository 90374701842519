/* -------------- header RWD --------------*/
@media (max-width: $w_pc_b){ 
    .header {
        .main-menu{
            > li{
                padding: 35px 0;
            }
            .main-link{
                font-size: 1.0625rem /* 17/16 */;
            }
        }
    }
}

// @media (max-width: $w_pc){ }

@media (max-width: 1200px){ 
    .header {
        .logo{
            width: 280px;
        }
        .nhl-link{
            @include size(40px);
        }
    }
}

@media (max-width: $w_pc_m){ 
    .header {
        .frame{
            padding-top: 15px;
            padding-bottom: 15px;
        }
        nav{
            @include size(min(80vw, 300px), 100dvh);
            background: var(--c-white);
            position: fixed;
            top: 0;
            left: 0;
            padding: 20px;
            overflow-y: auto;
            display: none;
        }
        .active-row{
            gap: 25px;
        }
        .main-menu{
            flex-direction: column;
            >li{
                border-bottom: 1px solid var(--c-grey);
                padding: 15px 0;
            }
        }
        .sub-menu{
            position: static;
            transform: translate(0);
            margin-top: 10px;
            width: 100%;
            a{
                padding: 8px 10px;
                text-align: left;
            }
        }
    }

    .mobile-toggle{
        display: block;
    }
}

@media (max-width: $w_tb_b){ 
    .top-header{
        button, a{
            @include size(40px);
            svg{
                font-size: 1rem /* 16/16 */;
            }
        }
        .keyword-form{
            grid-template-columns: auto 40px;
            input{
                font-size: .875rem /* 14/16 */;
                width: 180px;
            }
        }
    }
}

// @media (max-width: $w_tb){ }

@media (max-width: $w_tb_m){ 
    .header {
        .logo{
            width: 220px;
        }
        .active-row{
            gap: 0 15px;
        }
        .nhl-link{
            @include size(33px);
        }
    }
}

// @media (max-width: $w_mo){ }