/* -------------- download --------------*/
.page-download{
    .download-list{
        margin-top: -35px;
    }
    .download-item{
        align-items: center;
        border-bottom: 1px solid var(--c-border);
        display: grid;
        grid-template-columns: auto 120px;
        gap: 10px 20px;
        padding: 15px 0;
        .file-name{
            color: var(--c-font);
            font-size: 1.0625rem /* 17/16 */;
            line-height: 1.4;
            margin: 0;
        }
        .download-btn{
            background: #efefef;
            color: var(--c-primary);
            display: block;
            font-size: .875rem /* 14/16 */;
            line-height: 1;
            text-align: center;
            text-transform: uppercase;
            padding: 12px 5px;
            &:hover{
                background: var(--c-primary);
                color: var(--c-white);
            }
        }
    }
}