/* -------------- news RWD --------------*/
// @media (max-width: $w_pc_b){ }

@media (max-width: $w_pc){ 
    .page-news{
        .news-list{
            gap: 70px;
            padding: 35px 0;
        }
        .news-item{
            &:nth-child(1), &:nth-child(2), &:nth-child(3){
                &:before{
                    bottom: -35px;
                }
            }
        }
    }
}

// @media (max-width: $w_pc_m){ }

// @media (max-width: $w_tb_b){ }

// @media (max-width: $w_tb){ }

@media (max-width: $w_tb_m){ 
    .page-news{
        .news-gird .list-line{
            display: none;
        }
        .news-list{
            grid-template-columns: 1fr;
            gap: 0px;
            padding: 0;
        }
        .news-item{
            + .news-item{
                border-top: 1px solid #e5e5e5;
                padding-top: 25px;
                margin-top: 25px;
            }
            &:nth-child(1), &:nth-child(2), &:nth-child(3){
                &:before{
                    display: none;
                }
            }
        }
    }
}

// @media (max-width: $w_mo){ }