/* -------------- index RWD --------------*/
@media (max-width: 1680px){ 
    .index-news{
        padding-right: 40px;
    }
    .index-service{
        margin-top: 0;
        align-items: flex-end;
        
        .sec-head p{
            font-size: 2.5rem /* 40/16 */;
        }
        .right-sec{
            .more{
                @include size(180px, 60px);
                line-height: 60px;
            }
        }
        .content-sec .content{
            padding: 40px;
        }
    }
}

@media (max-width: $w_pc_b){ 
    .index-news {
        .news-main-grid{
            padding-right: 0;
        }
        .news-item{
            padding: 40px 30px;
        }
    }
    .index-service{
        align-items: flex-start;
        background: none;
        margin-bottom: 0;
        .content-sec{
            background: var(--c-primary);
        }
        .sec-head{
            top: 0;
            left: 0;
            transform: translateY(-100%);
            text-align: center;
            width: 100%;
            p{
                line-height: 1;
                writing-mode: inherit;
            }
        }
        .right-sec{
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

@media (max-width: $w_pc){ 
    .index-news{
        padding-top: 70px;
        padding-bottom: 100px;
    }
    .index-map{
        .map-grid{
            gap: 40px;
        }
        .map-info {
            .title{
                margin-top: 15px;
                margin-bottom: 10px;
            }
            .des a{
                font-size: 1rem /* 16/16 */;
            }
        }
    }
}

@media (max-width: $w_pc_m){ 
    .index-news {
        grid-template-columns: 1fr;
        .dec-img{
            display: none;
        }
        .news-list{
            max-width: 100%;
        }
        .news-item{
            padding: 30px;
        }
        .more-news{
            bottom: 30px;
        }
    }
    .index-service{
        flex-wrap: wrap;
        .content-sec{
            width: 100%;
        }
        .right-sec {
            width: 100%;
            .service-dots{
                bottom: 30px;
                left: 20px;
                right: initial;
            }
            .more{
                @include size(110px, 50px);
                line-height: 50px;
                margin-left: auto;
            }
        }
    }
    .index-map{
        padding-top: 80px;
    }
}

@media (max-width: $w_tb_b){ 
    .index-map{
        padding-top: 50px;
        .map-grid{
            gap: 20px;
        }
    }
}

@media (max-width: $w_tb){ 
    .index-map{
        .map-grid{
            grid-template-columns: repeat(2, 1fr);
            max-width: 500px;
            margin: auto;
        }
        .map{
            height: 500px;
            margin-top: -120px;
        }
    }
}

@media (max-width: $w_tb_m){ 
    .index-news {
        .news-main-grid{
            display: block;
        }
        .news-head{
            padding-top: 0;
            padding-left: 20px;
            writing-mode: inherit;
            &:before{
                top: 50%;
                height: 1px;
                width: 100%;
            }
            p{
                background: var(--c-white);
                display: inline-block;
                padding-right: 20px;
                position: relative;
            }
        }
        .news-item{
            padding: 25px 20px;
        }
    }
    .index-service{
        .sec-head p{
            font-size: 2rem /* 32/16 */;
        }
        .content-sec {
            .content{
                padding: 30px 20px;
            }
            .content-head{
                padding-bottom: 20px;
                .service-main{
                    font-size: 1.625rem /* 26/16 */;
                }
                .service-sub{
                    font-size: 1.25rem /* 20/16 */;
                }
            }
            .content-body{
                padding-top: 20px;
            }
        }
        .right-sec {
            .service-dots{
                bottom: 20px;
            }
            .slick-dots button{
                width: 20px;
            }
        }
    }
    .index-map{
        .map-grid{
            grid-template-columns: repeat(2, 1fr);
            gap: 15px;
        }
        .map-info {
            .title{
                margin-top: 10px;
                margin-bottom: 5px;
            }
            .des a{
                font-size: .9375rem /* 15/16 */;
            }
        }
        .map{
            height: 360px;
            margin-top: 40px;
        }
    }
}

@media (max-width: $w_mo){ 
    .index-news {
        padding-top: 40px;
        padding-bottom: 80px;
        padding-right: 0;
        .news-list{
            display: block;
        }
        .news-item{
            &:nth-child(2){
                border-left: none;
                border-top: 1px solid var(--c-border);
            }
        }
        .more-news{
            position: static;
            transform: translateX(0);
            margin: auto;
        }
    }
}