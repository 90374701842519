/* -------------- index --------------*/
.slick-list{
    line-height: 0;
}

.index-banner{
    .img-box{
        display: block;
        img{
            display: block;
            width: 100%;
        }
    }
}

.index-news{
    @extend %clearStyle;
    align-items: flex-start;
    display: grid;
    grid-template-columns: 40% auto;
    padding-top: 100px;
    padding-bottom: 100px;
    overflow-x: hidden;
    .dec-img{
        display: block;
        width: 100%;
    }
    .news-main-grid{
        display: flex;
        justify-content: space-between;
        padding-right: calc((100dvw - $w_pc_b) / 2 + 40px);
    }
    .news-head{
        color: var(--c-primary);
        font-size: 1.875rem /* 30/16 */;
        font-weight: bold;
        line-height: 1;
        padding-left: 30px;
        padding-top: 190px;
        position: relative;
        writing-mode: tb;
        &::first-letter{
            color: var(--c-success);
        }
        &:before{
            @include abs_pos($t:0, $l:45px);
            @include size(1px, 170px);
            background: var(--c-grey);
            content: '';
        }
    }
    .news-list{
        display: flex;
        max-width: 700px;
        position: relative;
    }
    .news-item{
        flex: 1;
        padding: 50px 40px;
        &:nth-child(2){
            border-left: 1px solid var(--c-border);
        }
    }
    .more-news{
        @include abs_pos($b:50px, $r:0);
        @include size(40px);
        @include flex($ai:center, $jfc:center);
        background: var(--c-grey);
        border-radius: 50%;
        color: var(--c-fontLight);
        font-size: .875rem /* 14/16 */;
        transform: translateX(100%);
        &:hover{
            background: var(--c-primary);
            color: var(--c-white);
        }
    }
}

.index-service{
    @extend %clearStyle;
    align-items: center;
    background: linear-gradient(90deg, $c_main 80%, $c_white 80%);
    display: flex;
    position: relative;
    margin-top: 60px;
    margin-bottom: 40px;
    .sec-head{
        @include abs_pos($t:30px, $l:50%);
        p{
            color: #54c8b0;
            font-size: 3.25rem /* 52/16 */;
            font-weight: bold;
            text-transform: uppercase;
            writing-mode: tb;
        }
    }
    .content-sec{
        width: 55%;
        .content{
            @include maw(540px);
            color: var(--c-white);
            padding: 70px 40px;
            margin: auto;
        }
        .content-head{
            border-bottom: 1px solid rgba(white, .5);
            padding-bottom: 25px;
            .service-main{
                font-size: 1.875rem /* 30/16 */;
                line-height: 1.2;
            }
            .service-sub{
                font-size: 1.5rem /* 24/16 */;
                line-height: 1.2;
                margin-top: 5px;
            }
        }
        .content-body{
            padding-top: 25px;
            ol, ul{
                padding-top: 10px;
            }
            ol{
                li + li{
                    margin-top: 5px;
                }
            }
            ul{
                li{
                    padding-left: 15px;
                    position: relative;
                    + li{
                        margin-top: 3px;
                    }
                    &:before{
                        @include abs_pos($t:0, $l:0);
                        @include size(5px);
                        background: var(--c-white);
                        content: '';
                        transform: rotate(45deg) translate(6px, 4px);
                    }
                }
            }
        }
    }
    .right-sec{
        margin-top: -60px;
        margin-bottom: -40px;
        position: relative;
        width: 45%;
        .service-dots{
            @include abs_pos($b:10px, $r:60%);
            align-items: flex-end;
            display: flex;
            gap: 10px;
            p{
                font-size: .875rem /* 14/16 */;
                line-height: 1;
            }
            .current-count{
                color: var(--c-fontLight);
            }
            .total-count{
                color: var(--c-font);
            }
        }
        .slick-dots{
            display: flex;
            gap: 5px;
            li{
                height: 3px;
            }
            .slick-active button{
                background: var(--c-success);
            }
            button{
                @include size(40px, 3px);
                background: #b5b5b5;
                display: block;
                font-size: 0;
                line-height: 0;
            }
        }
        .more{
            @include size(260px, 90px);
            border: 1px solid var(--c-success);
            background: var(--c-success);
            color: var(--c-white);
            display: block;
            font-size: .9375rem /* 15/16 */;
            text-transform: uppercase;
            text-align: center;
            line-height: 90px;
            margin-left: 50%;
            &:hover{
                background: var(--c-white);
                color: var(--c-success);
            }
        }
    }
}

.index-map{
    @extend %clearStyle;
    padding-top: 130px;
    .map-grid{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 60px;
    }
    .map-info{
        --c-main: var(--c-success);
        flex: 1;
        &:nth-child(2n){
            --c-main: var(--c-primary);
        }
        .box{
            border-radius: 50%;
            background: var(--c-main);
            height: 0;
            padding-bottom: 100%;
            position: relative;
            width: 100%;
        }
        .content{
            @extend %center;
            color: var(--c-white);
            width: 80%;
        }
        .icon{
            display: block;
            width: min(80px, 28%);
            margin: auto;
        }
        .title{
            text-align: center;
            position: relative;
            margin-top: 25px;
            margin-bottom: 20px;
            &:before{
                @include abs_pos($t:50%, $l:0);
                @include size(100%, 1px);
                background: var(--c-white);
                content: '';
                opacity: 0.7;
            }
            p{
                background: var(--c-main);
                display: inline-block;
                font-size: 1rem /* 16/16 */;
                line-height: 1;
                text-transform: uppercase;
                padding: 0 10px;
                position: relative;
            }
        }
        .des{
            max-width: 85%;
            margin: auto;
            text-align: center;
            a{
                color: var(--c-white);
                font-size: 1.125rem /* 18/16 */;
                line-height: 1.4;
            }
            .fax{
                cursor: auto;
            }
        }
    }
    .map{
        @include size(100%, 700px);
        margin-top: -150px;
        iframe{
            @include size(100%);
        }
    }
}