/* -------------- news --------------*/
.page-news{
    .news-gird{
        position: relative;
        .list-line{
            &:before, &:after{
                @include size(1px, 100%);
                background: linear-gradient(0deg, #e5e5e5 calc(50% - 20px), #fff calc(50% - 20px), #fff calc(50% + 20px), #e5e5e5 calc(50% + 20px));
                content: '';
                position: absolute;
                top: 0;
            }
            &:before{
                left: 31.5%;
            }
            &:after{
                left: 68.5%;
            }
        }
    }
    .news-list{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 100px;
        padding: 50px 0;
    }
    .news-item{
        position: relative;
        &:nth-child(1), &:nth-child(2), &:nth-child(3){
            &:before{
                @include abs_pos($b:-50px, $l:0);
                @include size(100%, 1px);
                content: '';
                background-image: radial-gradient(circle at 1px 1px, #e5e5e5 1px, transparent 0);
                background-size: 5px 1px;
            }
        }
    }
}