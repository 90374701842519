/* -------------- sitemap RWD --------------*/
@media (max-width: $w_pc_b){ 
    .page-sitemap{
        .sitemap-grid{
            gap: 40px;
        }
    }
}

// @media (max-width: $w_pc){ }

@media (max-width: $w_pc_m){ 
    .page-sitemap{
        .sitemap-grid{
            grid-template-columns: repeat(4, 1fr);
        }
    }
}

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .page-sitemap{
        .sitemap-grid{
            grid-template-columns: repeat(3, 1fr);
            gap: 40px 30px;
        }
    }
}

@media (max-width: $w_tb_m){ 
    .page-sitemap{
        .sitemap-head{
            margin-left: 0;
        }
        .sitemap-grid{
            grid-template-columns: repeat(2, 1fr);
            gap: 40px 25px;
            margin-top: 30px;
        }
    }
}

// @media (max-width: $w_mo){ }