/* -------------- member RWD --------------*/
@media (max-width: $w_pc_b){ 
    .page-member {
        .member-form {
            grid-template-columns: 25% 45% auto 40px;
            padding: 10px 0;
            .field{
                padding: 0 10px;
            }
        }
    }
}

// @media (max-width: $w_pc){ }

@media (max-width: 1100px){ 
    .page-member {
        .member-form {
            grid-template-columns: auto 50px;
            gap: 12px 0;
            .field{
                &:not(:last-child){
                    &:after{
                        display: none;
                    }
                }
            }
            .wide-field{
                grid-column: 1 / -1;
            }
        }
    }
}

@media (max-width: $w_pc_m){ 
    .page-member{
        .report-filter-row{
            flex-direction: column;
            margin-top: 20px;
            margin-bottom: 40px;
        }
        .report-main{
            table{
                tr{
                    display: block;
                }
                td{
                    display: block;
                    padding: 10px;
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: $w_tb_b){ 
    .page-member {
        .translation-remark{
            flex-direction: column;
            gap: 15px;
            padding: 20px 0 0;
        }
    }
}

@media (max-width: $w_tb){ 
    .page-member {
        .member-aside{
            margin: 5px 0 25px;
            a{
                font-size: 1rem /* 16/16 */;
                padding: 10px 12px;
            }
        }
        .report-main{
            .report-title{
                font-size: 1.125rem /* 18/16 */;
                margin-bottom: 15px;
            }
            .remark-row{
                flex-direction: column;
                padding: 15px 0;
                .rights{
                    margin-top: 10px;
                }
            }
        }
    }
}

@media (max-width: $w_tb_m){ 
    .page-member{
        .member-top-row{
            flex-direction: column;
            .member-info{
                margin-bottom: -10px;
            }
        }
        .login-form{
            .field{
                + .field{
                    margin-top: 15px;
                }
            }
            .field-content{
                input[type="text"], input[type="password"]{
                    padding: 8px 10px;
                }
            }
        }
        .member-form {
            .field{
                flex-wrap: wrap;
            }
            .select-style{
                margin-bottom: 5px;
            }
        }
        .report-main{
            margin-bottom: 20px;
            table{
                .field-title{
                    padding-right: 0;
                    padding-bottom: 5px;
                    width: 100%;
                }
            }
        }
        .translation-head{
            .title{
                font-size: 1.1875rem /* 19/16 */;
            }
            .des{
                font-size: 1rem /* 16/16 */;
            }
        }
        .translation-table{
            thead{
                display: none;
            }
            tr{
                display: block;
            }
            td{
                border-top: none;
                display: block;
                padding: 10px;
                width: 100%;
            }
            .item-name-td{
                background: #666;
                p{
                    color: var(--c-white);
                }
            }
            .no-title-td{
                padding-right: 50px;
                position: relative;
                &:after{
                    @include abs_pos($t:0, $r:0);
                    @include size(40px, 100%);
                    content: attr(data-no);
                    background: var(--c-success);
                    box-sizing: border-box;
                    color: var(--c-white);
                    font-size: 1rem /* 16/16 */;
                    text-align: center;
                    padding-top: 10px;
                }
            }
            .no-td{
                display: none;
            }
        }
    }
}

// @media (max-width: $w_mo){ }