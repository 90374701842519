/* -------------- layout RWD --------------*/
@media (max-width: $w_pc_b){ 
    .sep-aside-frame{
        gap: 0 60px;
    }
}

@media (max-width: $w_pc){ 
    .sep-aside-frame{
        grid-template-columns: 220px auto;
        gap: 0 40px;
    }
    .page-header .page-category{
        border-top-right-radius: 30% 95%;
        border-bottom-left-radius: 13% 40%;
        padding: 30px 20px 15px;
        margin-top: -57px;
    }
    aside .aside-menu a{
        font-size: 1.0625rem /* 17/16 */;
        padding: 12px 20px;
    }
    .table-style td{
        padding: 15px;
    }
}

@media (max-width: $w_pc_m){ 
    .sep-aside-frame{
        display: block;
    }
    .page-header {
        .sep-aside-frame{
            padding: 0;
        }
        .page-category{
            border-radius: 0;
            padding: 12px 40px;
            margin-top: 0;
            .sub{
                margin-bottom: 5px;
            }
            .main{
                font-size: 1.375rem /* 22/16 */;
            }
        }
        .annocement{
            padding: 10px 40px;
            gap: 15px;
            p, a{
                font-size: .9375rem /* 15/16 */;
            }
        }
    }
    aside{
        margin-top: 20px;
        .aside-menu{
            @include flex;
            gap: 5px;
        }
    }
    .page-main{
        padding-top: 15px;
        padding-bottom: 60px;
    }
}

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .page-header {
        .page-category{
            padding: 12px 20px;
        }
        .annocement{
            padding: 10px 20px;
        }
    }
}

@media (max-width: $w_tb_m){ 
    .news-item{
        .news-title{
            font-size: 1.25rem /* 20/16 */;
        }
        .date{
            margin-top: 10px;
        }
        .des{
            -webkit-line-clamp: 4;
            margin-top: 15px;
        }
        .more{
            margin: 20px auto 0;
            padding: 12px 10px;
        }
    }
    aside .aside-menu a{
        font-size: .9375rem /* 15/16 */;
        padding: 10px 15px;
    }
    .breadcrumb{
        justify-content: flex-start;
        padding-top: 30px;
        li:not(:last-child):after{
            top: 1px;
        }
        a, p{
            font-size: .8125rem /* 13/16 */;
        }
    }
    .page-main{
        padding-top: 10px;
        padding-bottom: 40px;
        .page-main-head{
            padding-bottom: 10px;
            margin-bottom: 30px;
            .main-title:after{
                bottom: -14px;
            }
        }
        .page-dl-head{
            .head-title{
                p{
                    font-size: 1.1875rem /* 19/16 */;
                }
            }
            .dl-info{
                margin-top: 10px;
                gap: 10px;
            }
        }
        .page-dl-head{
            margin-bottom: 20px;
        }
    }
    .back-row{
        padding: 10px 0;
        .back-btn{
            svg, span{
                font-size: .875rem /* 14/16 */;
            }
        }
    }
}

// @media (max-width: $w_mo){ }