/* -------------- layout --------------*/
* {
	box-sizing: border-box;
	outline: none;
	word-break: break-word;
}

html, body{
	@include maw(1920px);
	padding: 0;
	margin: 0 auto;
}

body{
	font-family: 'Open Sans', Arial, 'LiHei Pro', 'fix Microsoft JhengHei', 'Microsoft JhengHei','新細明體', sans-serif;
}

.hide{
	display: none !important;
}

.clearfix{
	@extend %clearfix;
}

a, button{
	text-decoration: none;
	transition: all .4s ease;
}

.unreset{
	@extend %clearfix;
	line-height: initial;
	a{
		text-decoration: underline;
		transition: none;
	}
	img{
		max-width: 100% !important;
		height: auto !important; 
	}
	.oembed-provider-youtube {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		iframe, object, embed{
			@include size(100%);
			@include abs_pos($t:0, $l:0);
		}
	}
	p{
		font-size: 1rem /* 16/16 */;
		line-height: 1.6;
	}
}

.img-limit{
	position: relative;
	&.service-img{
		@include fiximg(100%, calc(520 / 995 * 100%));
	}
}

.news-item{
	.news-title{
		color: var(--c-font);
		font-size: 1.375rem /* 22/16 */;
		font-weight: bold;
		line-height: 1.4;
		&:hover{
			color: var(--c-primary);
		}
	}
	.date{
		color: var(--c-success);
		font-size: 1rem /* 16/16 */;
		font-weight: lighter;
		line-height: 1;
		margin-top: 25px;
	}
	.des{
		@include lineLimit(9);
		color: var(--c-font);
		font-size: 1rem /* 16/16 */;
		line-height: 1.6;
		margin-top: 30px;
	}
	.more{
		border: 1px solid var(--c-primary);
		border-radius: 3px;
		display: block;
		color: var(--c-primary);
		font-size: .875rem /* 14/16 */;
		line-height: 1;
		text-transform: uppercase;
		margin: 30px auto 0;
		text-align: center;
		padding: 17px 10px;
		width: 150px;
		&:hover{
			background: var(--c-primary);
			color: var(--c-white);
		}
	}
}

.page-banner{
	img{
		display: block;
		width: 100%;
	}
}

.sep-aside-frame{
	align-items: flex-start;
	display: grid;
	grid-template-columns: 280px auto;
	gap: 0 80px;
}

.page-header{
	@extend %clearStyle;
	background: #f7f7f7;
	.page-category{
		background: var(--c-success);
		border-top-right-radius: 35% 100%;
		border-bottom-left-radius: 15% 45%;	
		color: var(--c-white);
		padding: 40px 30px 20px;
		margin-top: -72px;
		.sub{
			font-size: 1rem /* 16/16 */;
			text-transform: uppercase;
			opacity: .5;
			margin-bottom: 10px;
		}
		.main{
			font-size: 1.625rem /* 26/16 */;
			font-weight: bold;
			line-height: 1;
		}
	}
	.annocement{
		display: flex;
		padding: 15px 0;
		gap: 25px;
		p, a{
			font-size: 1rem /* 16/16 */;
			line-height: 1;
		}
		.title{
			color: var(--c-font);
			font-weight: bold;
			flex-shrink: 0;
		}
		.anno-link{
			color: var(--c-success);
			display: inline-block;
			&:hover{
				text-decoration: underline;
			}
		}
	}
}

aside{
	@extend %clearStyle;
	.aside-menu{
		li{
			+ li{
				margin-top: 1px;
			}
			&.active{
				a{
					background: #f1e6f2;
				}
			}
		}
		a{
			background: #eaeaea;
			color: var(--c-font);
			display: block;
			font-size: 1.1875rem /* 19/16 */;
			line-height: 1.2;
			padding: 15px 30px;
			&:hover{
				background: transparent;
				color: var(--c-success);
			}
		}
	}
}

.breadcrumb{
	@include flex($jfc:flex-end);
	list-style: none;
	margin: 0;
	padding-left: 0;
	padding-top: 15px;
	li{
		@include lineLimit(1);
		max-width: 120px;
		&:not(:last-child){
			padding-right: 15px;
			position: relative;
			&:after{
				@include abs_pos($t:2px, $r:3px);
				content: '\f105';
				color: var(--c-fontLight);
				font-family: 'Font Awesome 6 Free';
				font-weight: bold;
				font-size: .75rem /* 12/16 */;
			}
		}
	}
	a, p{
		color: var(--c-fontLight);
		display: block;
		font-size: .9375rem /* 15/16 */;
		line-height: 1.1;
		margin: 0;
	}
	a:hover{
		color: var(--c-primary);
	}
}

.page-main{
	padding-top: 20px;
	padding-bottom: 90px;
	.page-main-head{
		border-bottom: 1px solid var(--c-border);
		padding-bottom: 15px;
		margin-bottom: 40px;
		.main-title{
			&:after{
				@include abs_pos($l:0, $b:-19px);
				@include size(100%, 2px);
				background: black;
				content: "";
			}
		}
		p, a{
			color: var(--c-font);
			display: inline-block;
			font-size: 1.375rem /* 22/16 */;
			line-height: 1;
			margin: 0;
			position: relative;
			+ p, + a{
				margin-left: 15px;
			}
		}
		a:hover{
			color: var(--c-primary);
		}
	}
	.page-dl-head{
		@extend %clearStyle;
		.head-title{
			p{
				color: var(--c-font);
				font-size: 1.375rem /* 22/16 */;
				font-weight: bold;
				line-height: 1.4;
			}
		}
		.dl-info{
			align-items: center;
			display: flex;
			margin-top: 15px;
			gap: 20px;
			.date{
				color: var(--c-success);
				font-size: 1rem /* 16/16 */;
				font-weight: lighter;
				line-height: 1;
			}
		}
	}
	.a2a_kit{
		display: flex;
		a{
			padding: 0 !important;
			display: block;
			+ a{
				margin-left: 10px;
			}
			img{
				@include size(30px);
				display: block;
			}
		}
	}
	.page-dl-head{
		margin-bottom: 40px;
	}
}

.table-style{
	border-collapse: collapse;
	width: 100%;
	th, td{
		border: solid 1px #e8e8e8;
		p{
			color: var(--c-font);
			font-size: 1rem /* 16/16 */;
			line-height: 1.4;
		}
	}
	th{
		padding: 12px;
		text-align: center;
	}
	td{
		padding: 20px 30px;
	}
	ul{
		margin: 0;
		padding-left: 20px;
		li + li{
			margin-top: 3px;
		}
	}
	.grey-td-1{
		background: #f8f8f8;
	}
	.grey-td-2{
		background: #b2b2b2;
		p{
			color: var(--c-white) !important;
		}
	}
	.grey-td-3{
		background: #999;
		p{
			color: var(--c-white) !important;
		}
	}
	.grey-td-4{
		background: #666666;
		p{
			color: var(--c-white) !important;
		}
	}
}

.back-row{
	background: #f7f7f7;
	padding: 15px 0;
	.frame{
		text-align: right;
	}
	.back-btn{
		color: var(--c-font);
		display: inline-flex;
		gap: 8px;
		&:hover{
			color: var(--c-primary);
		}
		svg, span{
			font-size: .9375rem /* 15/16 */;
			line-height: 1;
		}
	}
}