/* -------------- temp RWD --------------*/
@media (max-width: $w_pc_b){ 
    .page-contact{
        .map-row{
            .traffic-grid{
                padding: 40px 30px;
            }
        }
    }
}

// @media (max-width: $w_pc){ }

@media (max-width: $w_pc_m){ 
    .page-contact{
        .info{
            width: 50%;
        }
    }
}

@media (max-width: $w_tb_b){ 
    .page-contact{
        .map-row{
            grid-template-columns: 1fr;
        }
        .traffic-table{
            th, td{
                padding: 13px 7px;
            }
        }
    }
}

// @media (max-width: $w_tb){ }

@media (max-width: $w_tb_m){ 
    .page-contact{
        .info{
            width: 100%;
        }
        .map-row{
            .traffic-grid{
                padding: 0;
            }
        }
        .traffic-table{
            th{
                b{
                    padding-left: 0;
                    padding-top: 5px;
                }
            }
            .bus-td{
                width: 65px;
            }
        }
    }
}

// @media (max-width: $w_mo){ }