/* -------------- footer --------------*/
.footer{
    @extend %clearStyle;
    background: var(--c-primary);
    padding: 50px 0 60px;
    .frame{
        @include flex($jfc:space-between);
    }
    .logo{
        width: 380px;
        img{
            display: block;
            width: 100%;
        }
    }
    .ft-menu{
        @include flex;
        gap: 5px 25px;
        a{
            color: var(--c-white);
            font-size: 1.0625rem /* 17/16 */;
            line-height: 1.1;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .web-rights{
        @include flex($jfc:flex-end);
        margin-top: 15px;
        gap: 5px 20px;
        p{
            font-size: .9375rem /* 15/16 */;
            line-height: 1.1;}
        .rights{
            color: var(--c-white);
        }
        .designed, b{
            color: #c2e2da;
        }
    }
}

.top-btn{
    @include size(50px);
    background: var(--c-light);
    position: fixed;
    top: 40dvh;
    right: 0;
    display: none;
    &:hover{
        background: var(--c-primary);
        span{
            color: var(--c-white);
        }
    }
    span{
        @extend %center;
        color: var(--c-primary);
        display: block;
        font-size: .875rem /* 14/16 */;
        line-height: 1;
        text-align: center;
        width: 100%;
    }
}